import Swiper from 'swiper';
import { FreeMode, Thumbs, EffectCreative, EffectFade, Controller, Autoplay } from "swiper/modules";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
  const videoPlayer = document.querySelector('.video__item');

  let mobile = null;

  function handleDOMContentLoaded() {
    const thumbSwiper = new Swiper('.slider__thumb', {
      modules: [ FreeMode ],
      loop: true,
      slidesPerView: 2,
      freeMode: true,
      watchSlidesProgress: true
    });

    const textSwiper = new Swiper('.slider__text', {
      modules: [ EffectFade ],
      speed: 700,
      slidesPerView: 1,
      loop: true,
      effect: "fade",
      enabled: false,
      fadeEffect: {
        crossFade: true
      }
    });

    new Swiper('.slider__media', {
      modules: [ Thumbs, EffectCreative, Controller, Autoplay ],
      speed: 700,
      controller: {
        control: textSwiper,
      },
      effect: "creative",
      loop: true,
      autoplay: {
        delay: 6000,
        pauseOnMouseEnter: true
      },
      thumbs: {
        swiper: thumbSwiper
      },
      creativeEffect: {
        prev: {
          shadow: true,
          translate: ["-20%", 0, -1],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      }
    });

    if (videoPlayer) {
      mobile = window.matchMedia('(max-width: 767px)');
      handleVideo(mobile);
      mobile.addListener(handleVideo);
    }
  }

  function handleVideo(e) {
    const videoPath = videoPlayer.dataset.video;
    const videoMobilePath = videoPlayer.dataset.videoMobile;
    videoPlayer.setAttribute('src', e.matches ? videoMobilePath : videoPath);
  }
})();
